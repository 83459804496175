import "./style.css";
import market from "./img/market.svg";
import balls from "./img/balls.png";
import circles from "./img/circles.svg";
import ach from "./img/achive.svg";
import arrow from "./img/arrow.svg";
import agree from "./img/agree.svg";
import icon1 from "./img/icon1.svg";
import icon2 from "./img/icon2.svg";
import icon3 from "./img/icon3.svg";
import icon4 from "./img/icon4.svg";
import icon5 from "./img/icon5.svg";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";
import LightSpeed from "react-reveal/LightSpeed";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { routes } from "../../routes";
export default function MarketPlace({ isForm }) {
  const [slider, setSlider] = useState(1);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const Navigate = useNavigate();
  const [error, setError] = useState(false);
  useEffect(() => {
    if (tel.length > 5) {
      setError(false);
    }
  }, [tel]);
  function sendMessage() {
    let form = new FormData();
    form.append("tel", tel);
    form.append("name", name);
    form.append("email", email);
    fetch("https://brandside.pro/amocrm/webhook.php", {
      method: "POST",
      body: JSON.stringify({
        phone: tel,
        name: name,
        email: email,
        url_main: "https://брендсайд.рф",
      }),
    }).then((response) => {
      if (response.status == 200) {
        fetch("https://брендсайд.рф/bot.php", {
          method: "post",
          body: form,
        }).then((response) => {
          if (response.status == 200) {
            window.location.href = "https://брендсайд.рф/thanks";
          }
        });
      }
    });
  }
  return (
    <div className="market">
      <div className="market_body">
        <img src={arrow} alt="" className="marketArrow" />
        <p className="special">Cпециальное предложение для собственников</p>
        <Zoom right>
          <h3>
            магазинов <img src={market} alt="" /> <br />
            на Маркетплейсах
          </h3>
        </Zoom>
        <div className="market_content">
          <div className="leftMarket">
            <ul>
              <li>
                <div>1</div>
                <p>Онлайн-подача заявки</p>
              </li>
              <li>
                <div>2</div>
                <p>Бесплатный анализ уникальности</p>
              </li>
              <li>
                <div>3</div>
                <p>30% скидка на госпошлины</p>
              </li>
              <li>
                <div>4</div>
                <p>Уведомление о заявке(форма 940)</p>
              </li>
              <li>
                <div>5</div>
                <p>Бонусные консультации</p>
              </li>
              <li>
                <div>6</div>
                <p>Оперативная поддержка</p>
              </li>
              <li>
                <div>7</div>
                <p>Рассрочка от 2х месяцев 0%</p>
              </li>
            </ul>
          </div>
          <div className="rightMarket">
            <Zoom top>
              <img src={balls} alt="" />
            </Zoom>
          </div>
        </div>
        <div className="marketForm">
          <div className="marketFormLeft">
            <p>Заполните форму</p>
            <span>Зарегистрируем обращение за 1 минуту</span>
            <div className="inputTittle">Ваше имя</div>
            <input type="text" onChange={(e) => setName(e.target.value)} />
            <div className="inputTittle">Ваш телефон *</div>
            <ReactInputMask
              mask="+7 999 999 99 99"
              maskChar={null}
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              placeholder=""
              style={error ? { border: "2px solid red" } : {}}
            />
            <div className="inputTittle">Ваша почта</div>
            <input type="email" onChange={(e) => setEmail(e.target.value)} />
            <LightSpeed>
              <div
                className="button"
                onClick={() =>
                  tel.length > 5 ? sendMessage() : setError(true)
                }
              >
                Подать заявку <span class="flare"></span>
              </div>
            </LightSpeed>
            <div className="agree">
              <img src={agree} alt="" />
              <p>
                Я <span>cогласен(а) на обработку</span> моих персональных данных
              </p>
            </div>
          </div>
          <div className="marketFormRight">
            <p>Это быстро!</p>
            <div className="circlescont">
              <img src={circles} alt="" />
              <div className="rightCircles">
                <Bounce right>
                  <p>1 минута</p>
                  <span>Заполнить заявку</span>
                </Bounce>
                <Bounce right delay={100}>
                  <p>3 часа</p>
                  <span>Закрепление права на знак</span>{" "}
                </Bounce>
                <Bounce right delay={200}>
                  <p>от 3.5 месяцев</p>
                  <span>Экспертиза в Роспатенте</span>{" "}
                </Bounce>
                <Bounce right delay={300}>
                  <p>от 10 дней</p>
                  <span>Успешная регистрация и выдача свидетельства</span>{" "}
                </Bounce>
              </div>
            </div>
          </div>
        </div>
        <div className="marketWork">
          <div className="marketWorkLeft">
            <h2>
              Качественная <br />
              работа <img src={ach} alt="" />
            </h2>
            <p>
              Проводим детальное исследование, чтобы избежать неожиданностей!
            </p>
          </div>
          <div className="marketWorkRight">
            <p>100%</p>
            <span>вовлечённость в процесс</span>
          </div>
        </div>
        <div
          className="elemsMarket"
          style={
            slider == 1
              ? { transform: "translateX(0)" }
              : slider == 2
              ? { transform: "translateX(-100vw)" }
              : slider == 3
              ? { transform: "translateX(-200vw)" }
              : slider == 4
              ? { transform: "translateX(-300vw)" }
              : slider == 5
              ? { transform: "translateX(-400vw)" }
              : {}
          }
        >
          <Zoom top>
            <div className="marketElem">
              <img src={icon1} alt="" />
              <p>Проверка</p>
              <span>тождества и сходства обозначений</span>
            </div>
          </Zoom>
          <Zoom top delay={100}>
            <div className="marketElem">
              <img src={icon2} alt="" />
              <p>Поиск</p>
              <span>по фирменным наименованиям российских компаний</span>
            </div>
          </Zoom>
          <Zoom top delay={200}>
            <div className="marketElem">
              <img src={icon3} alt="" />
              <p>Консультация</p>
              <span>
                эксперта на предмет оценки силы обозначения как товарного знака
              </span>
            </div>
          </Zoom>
          <Zoom top delay={300}>
            <div className="marketElem">
              <img src={icon4} alt="" />
              <p>Проверка</p>
              <span>
                соответствия обозначения бренд-стратегии и позиционированию
                компании
              </span>
            </div>
          </Zoom>
          {document.body.clientWidth < 640 && (
            <div className="marketElem">
              <img src={icon5} alt="" />
              <p>Анализ</p>
              <span>
                юридических рисков отказа в регистрации или последующего
                оспаривания
              </span>
            </div>
          )}
        </div>
        {document.body.clientWidth < 640 && (
          <div className="circlesEm">
            <div
              onClick={() => {
                setSlider(1);
              }}
              className="ems"
              style={slider == 1 ? { background: "#3506EF" } : {}}
            ></div>
            <div
              onClick={() => {
                setSlider(2);
              }}
              className="ems"
              style={slider == 2 ? { background: "#3506EF" } : {}}
            ></div>
            <div
              onClick={() => {
                setSlider(3);
              }}
              className="ems"
              style={slider == 3 ? { background: "#3506EF" } : {}}
            ></div>
            <div
              onClick={() => {
                setSlider(4);
              }}
              className="ems"
              style={slider == 4 ? { background: "#3506EF" } : {}}
            ></div>
            <div
              onClick={() => {
                setSlider(5);
              }}
              className="ems"
              style={slider == 5 ? { background: "#3506EF" } : {}}
            ></div>
          </div>
        )}
        <div className="situation">
          {document.body.clientWidth > 640 && (
            <div className="marketElem">
              <img src={icon5} alt="" />
              <p>Анализ</p>
              <span>
                юридических рисков отказа в регистрации или последующего
                оспаривания
              </span>
            </div>
          )}
          <div className="situationEm">
            <p>У вас особенный случай? </p>
            <div className="situationFlex">
              <p>Поможем в любой ситуации!</p>
              <div className="button" onClick={() => isForm(true)}>
                РАССКАЗАТЬ СИТУАЦИЮ <span class="flare"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
