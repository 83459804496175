import Header from "../../Components/Header";
import "./style.css";
import logo from "./img/logo.png";
import tg from "./img/tg.svg";
import wt from "./img/wt.svg";
import girl from "./img/girl.png";
import first from "./img/1.svg";
import second from "./img/2.svg";
import third from "./img/3.svg";
import mobRaz from "./img/mobRaz.png";
import Smart from "../../Components/Smart";
import Footer from "../../Components/Footer";
import uhu from "./img/eeea.png";
import pusik from "./img/pus.png";
import sub from "./img/sub.svg";
import downs from "./img/ggg.png";
import { useEffect, useState } from "react";
import FormL from "../../Components/Form";
import mobText from "./img/mobText.svg";
export default function Thanks() {
  const [form, isForm] = useState(false);

  return (
    <div className="posad thankss">
      {form && <FormL closePop={isForm}></FormL>}
      <div className="headPosad">
        <div className="headerTop">
          <img src={logo} alt="" className="logo" />
          <p className="dostavka">
            <b>Бесплатная</b> Экспресс- <br />
            проверка товарного знака
          </p>
          <div className="button button_header" onClick={() => isForm(true)}>
            Заказать <span class="flare"></span>
          </div>
          <p className="askMe">Задайте ваш вопрос</p>
          <a
            href="https://t.me/brandsideRF_bot"
            target="_blank"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={tg} alt="" className="icon" />
          </a>

          <a href="tel:88002220403">8 (800) 222-04-03</a>
        </div>

        <div className="posadHeadCont">
          <img src={pusik} className="pusik" alt="" />
          <div className="posadHeadLeftCont">
            <h2>
              <b
                style={
                  document.body.clientWidth > 640
                    ? { fontSize: "6.5vw" }
                    : { fontSize: "11.5vw" }
                }
              >
                Поздравляем!
              </b>
              <div
                style={
                  document.body.clientWidth > 640
                    ? { fontSize: "3.7vw" }
                    : { fontSize: "8.5vw" }
                }
              >
                У вас все получилось
              </div>
            </h2>
            <div className="prs">В самое ближайшее время</div>
            <p className="ths">
              с вами свяжется наш специалист и даст подробные ответы на все
              вопросы
            </p>
            <img
              src={document.body.clientWidth > 640 ? sub : mobText}
              style={
                document.body.clientWidth > 640
                  ? { width: "27vw", marginTop: "1vw" }
                  : { width: "100%" }
              }
              alt=""
            />

            {document.body.clientWidth > 640 && (
              <div
                className="btnP ole"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <a
                  className="button"
                  href="https://t.me/brandsideRF_bot"
                  target="_blank"
                >
                  ПОДПИСАТЬСЯ <span class="flare"></span>
                </a>
                <span className="obeshf">Обещаем - никакого спама</span>
              </div>
            )}
          </div>
          <div className="posadHeadRightCont">
            <img
              src={document.body.clientWidth > 640 ? girl : uhu}
              alt=""
              style={{ top: "1.5vw" }}
            />
            {document.body.clientWidth < 640 && (
              <div className="btnP">
                <div className="koks">
                  <p>Экспресс-проверка</p>
                  <div>БЕСПЛАТНО!</div>
                </div>
                <a
                  className="button"
                  href="https://t.me/brandsideRF_bot"
                  target="_blank"
                >
                  ПОДПИСАТЬСЯ <span class="flare"></span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      {document.body.clientWidth > 640 ? (
        <img src={downs} className="downs" alt="" />
      ) : (
        <img src={mobRaz} className="downs" alt="" />
      )}

      <div className="changeColor">
        <Footer></Footer>
      </div>
    </div>
  );
}
