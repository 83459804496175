import "./style.css";
import tel from "./img/tel.svg";
import mail from "./img/mail.svg";
import logo from "./img/logo.png";
export default function Footer() {
  return (
    <div className="footer">
      <div className="footerBody">
        <div className="firstFooter">
          <img src={logo} alt="" />
          <div className="types">
            <img src={mail} alt="" />
            <a href="mailto:INFO@BRANDSIDE.RU">INFO@BRANDSIDE.RU</a>
          </div>
          <div className="types">
            <img src={tel} alt="" />
            <a href="tel:+84 38 242 5892" className="telHref">
              +84 38 242 5892
            </a>
          </div>
        </div>
        <div className="secondFooter">
          <p>г. Москва, ул. Садовая-Спасская, д. 1/2, корп. 4</p>
          <div className="grayLine"></div>
          <p>ПН-ПТ с 10:00 до 19:00</p>
          <a href="./politic.html" target="_blank" className="politics">
            Политика конфидециальности
          </a>
        </div>
        <div className="ofert">*Предложение не является публичной офертой</div>
      </div>
    </div>
  );
}
