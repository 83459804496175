import Header from "../../Components/Header";
import "./style.css";
import logo from "./img/logo.png";
import tg from "./img/tg.svg";
import wt from "./img/wt.svg";
import girl from "./img/girl.png";
import first from "./img/1.svg";
import second from "./img/2.svg";
import Bounce from "react-reveal/Bounce";
import third from "./img/3.svg";
import mobRaz from "./img/mobRaz.png";
import Smart from "../../Components/Smart";
import Footer from "../../Components/Footer";
import uhu from "./img/eeea.png";
import pusik from "./img/pus.png";
import downs from "./img/ggg.png";
import { useState } from "react";
import FormL from "../../Components/Form";
import { Flip, Zoom } from "react-reveal";
export default function Posad() {
  const [form, isForm] = useState(false);
  return (
    <div className="posad">
      {form && <FormL closePop={isForm}></FormL>}
      <div className="headPosad">
        <div className="headerTop">
          <img src={logo} alt="" className="logo" />
          <p className="dostavka">
            <b>Бесплатная</b> Экспресс- <br />
            проверка товарного знака
          </p>
          <div className="button button_header" onClick={() => isForm(true)}>
            Заказать <span class="flare"></span>
          </div>
          <p className="askMe">Задайте ваш вопрос</p>
          <a
            href="https://t.me/oporadigital_bot"
            target="_blank"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={tg} alt="" className="icon" />
          </a>

          <a href="tel:+84 38 242 5892">+84 38 242 5892</a>
        </div>
        <div className="posadHeadCont">
          <img src={pusik} className="pusik" alt="" />
          <div className="posadHeadLeftCont">
            <p className="zach">Защитите свою компанию</p>
            <h2>
              <Bounce left>
                <b>ЗАРЕГИСТРИРУЙТЕ</b>
              </Bounce>
              <Bounce left>
                <div>ТОВАРНЫЙ ЗНАК</div>
              </Bounce>
            </h2>
            <div className="pr">
              <span>от</span> 11 500 ₽
            </div>
            {document.body.clientWidth > 640 && (
              <div className="pus">
                <Zoom top>
                  {" "}
                  <div className="pusEm">
                    <img src={first} alt="" />
                    <div>
                      <p>
                        в течение <br /> 3-х часов
                      </p>
                      <span>
                        Закрепим ваше <br /> право на знак
                      </span>
                    </div>
                  </div>
                </Zoom>{" "}
                <Zoom top delay={200}>
                  <div className="pusEm">
                    <img src={second} alt="" />
                    <div>
                      <p>
                        100% <br /> прозрачность
                      </p>
                      <span>процесса</span>
                    </div>
                  </div>
                </Zoom>
                <Zoom top delay={400}>
                  <div className="pusEm">
                    <img src={third} alt="" />
                    <div>
                      <p>
                        0% <br /> Рассрочка
                      </p>
                      <span>от 2х месяцев</span>
                    </div>
                  </div>
                </Zoom>
              </div>
            )}
            {document.body.clientWidth > 640 && (
              <div className="btnP">
                <div className="koks">
                  <p>Экспресс-проверка</p>
                  <div>БЕСПЛАТНО!</div>
                </div>
                <div className="button" onClick={() => isForm(true)}>
                  ПРОВЕРИТЬ <span class="flare"></span>
                </div>
              </div>
            )}
          </div>
          <div className="posadHeadRightCont">
            <Bounce right>
              <img src={document.body.clientWidth > 640 ? girl : uhu} alt="" />
            </Bounce>
            {document.body.clientWidth < 640 && (
              <div className="btnP">
                <div className="koks">
                  <p>Экспресс-проверка</p>
                  <div>БЕСПЛАТНО!</div>
                </div>
                <div className="button" onClick={() => isForm(true)}>
                  ПРОВЕРИТЬ <span class="flare"></span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {document.body.clientWidth > 640 ? (
        <img src={downs} className="downs" alt="" />
      ) : (
        <img src={mobRaz} className="downs" alt="" />
      )}
      <Smart isForm={isForm}></Smart>
      <Footer></Footer>
    </div>
  );
}
